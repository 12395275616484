import { createSlice } from "@reduxjs/toolkit"
import compact from "lodash/compact"
import uniq from "lodash/uniq"

import CONFIG from "../../config"
import callHasura from "../callHasura"
import { ConceptByValue, ConceptByValue_concepts } from "../queries/types/ConceptByValue"
import { ConceptPassageCounts, ConceptPassageCounts_concepts } from "../queries/types/ConceptPassageCounts"
import { Concepts, Concepts_concepts } from "../queries/types/Concepts"
import { Grammar } from "../../components/concept/grammar"
import { InsertConcept, InsertConcept_insert_concepts_one } from "../queries/types/InsertConcept"
import { NotificationId, setNotificationAction } from "./notification"
import { Roots_roots } from "../queries/types/Roots"
import { UpdateConcept_update_concepts_by_pk } from "../queries/types/UpdateConcept"
import { User } from "../queries/types/User"
import { defaultSetLoading, defaultNetworkingFailure, defaultNetworkingSuccess } from "./common"
import { fetchUserQuery } from "../queries/user"
import { root_appearances_insert_input } from "../../../types/globalTypes"
import { updateAdminConceptsQueueAction } from "./user"

import {
  fetchConceptPassageCountsQuery,
  deleteConceptQuery,
  deleteRootAppearanceQuery,
  fetchConceptQuery,
  fetchConceptsQuery,
  fetchRootsQuery,
  insertConceptQuery,
  insertRootAppearanceQuery,
  updateConceptQuery,
  deleteConceptImageAppearanceQuery,
  insertConceptImageAppearanceQuery,
  insertConceptsQuery,
} from "../queries/concept"

export type Concept = ConceptByValue_concepts | InsertConcept_insert_concepts_one

export interface ConceptState {
  concept?: Concept
  concepts?: Concepts_concepts[]
  isQuerying: { [id: string]: boolean }
  roots: Roots_roots[]
  conceptPassageCounts?: ConceptPassageCounts_concepts[]
}

const initialState: ConceptState = {
  isQuerying: {},
  roots: [],
}

const conceptSlice = createSlice({
  name: "concept",
  initialState,
  reducers: {
    networkingFailure: defaultNetworkingFailure,
    networkingSuccess: defaultNetworkingSuccess,
    setLoading: defaultSetLoading,

    unsetConcept: (state) => {
      state.concept = undefined
    },

    setConcept: (state, { payload }: { payload: Concept }) => {
      state.concept = payload
    },

    fetchConceptsSuccess: (state, { payload }: { payload: Concepts_concepts[] }) => {
      state.concepts = payload
    },

    fetchRootsSuccess: (state, { payload }: { payload: Roots_roots[] }) => {
      state.roots = payload
    },

    fetchConceptPassageCountsSuccess: (state, { payload }: { payload: ConceptPassageCounts_concepts[] }) => {
      state.conceptPassageCounts = payload
    },
  },
})

export const {
  setLoading,
  networkingFailure,
  networkingSuccess,
  setConcept,
  fetchRootsSuccess,
  fetchConceptsSuccess,
  unsetConcept,
  fetchConceptPassageCountsSuccess,
} = conceptSlice.actions

export const conceptSelector = (state: any) => state.concept

export default conceptSlice.reducer

export function fetchConceptAction(accessToken: string, display_name: string, curriculum_id: number = CONFIG.CORE_CURRICULUM_ID) {
  return async (dispatch: any) => {
    const query = fetchConceptQuery(display_name, curriculum_id)
    dispatch(setLoading(query.name))

    try {
      const result: ConceptByValue = await callHasura(accessToken, query)

      if (result.concepts.length) {
        dispatch(setConcept(result.concepts[0]))
      } else {
        const result: InsertConcept = await callHasura(accessToken, insertConceptQuery({ display_name, curriculum_id: CONFIG.CORE_CURRICULUM_ID }))
        if (result.insert_concepts_one) dispatch(setConcept(result.insert_concepts_one))
      }
      dispatch(networkingSuccess(query.name))
    } catch (error: any) {
      dispatch(networkingFailure([query.name, error.message]))
    }
  }
}

export function fetchConceptsAction(accessToken: string, curriculum_id?: number) {
  return async (dispatch: any) => {
    const query = fetchConceptsQuery(curriculum_id)
    dispatch(setLoading(query.name))

    try {
      const result: Concepts = await callHasura(accessToken, query)
      dispatch(fetchConceptsSuccess(result.concepts))
      dispatch(networkingSuccess(query.name))
    } catch (error: any) {
      dispatch(networkingFailure([query.name, error.message]))
    }
  }
}

export function fetchConceptPassageCountsAction(accessToken: string) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      const result: ConceptPassageCounts = await callHasura(accessToken, fetchConceptPassageCountsQuery())
      dispatch(fetchConceptPassageCountsSuccess(result.concepts))
      dispatch(networkingSuccess())
    } catch (error: any) {
      dispatch(networkingFailure())
    }
  }
}

export function deleteConceptImageAppearanceAction(accessToken: string, id: number) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, deleteConceptImageAppearanceQuery(id))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function insertConceptImageAppearanceAction(accessToken: string, image_id: number, concept_id: number) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, insertConceptImageAppearanceQuery({ image_id, concept_id }))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function insertConceptAndConceptImageAppearanceAction(accessToken: string, image_id: number, display_name: string) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      const result: InsertConcept = await callHasura(accessToken, insertConceptQuery({ display_name, curriculum_id: CONFIG.CORE_CURRICULUM_ID }))
      const concept_id = result.insert_concepts_one?.id
      if (concept_id) await callHasura(accessToken, insertConceptImageAppearanceQuery({ image_id, concept_id }))
      dispatch(fetchConceptsAction(accessToken))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function deleteConceptAction(accessToken: string, id: number) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, deleteConceptQuery(id))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function updateConceptAction(
  accessToken: string,
  id: number,
  difficulty: number | null,
  definition: string | null,
  grammar: Grammar | null,
  shouldSetConcept = true
) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      const concept: UpdateConcept_update_concepts_by_pk = (await callHasura(accessToken, updateConceptQuery(id, difficulty, definition, grammar)))
        .update_concepts_by_pk
      if (shouldSetConcept) dispatch(setConcept(concept))
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function fetchRootsAction(accessToken: string) {
  return async (dispatch: any) => {
    const query = fetchRootsQuery()
    dispatch(setLoading())

    try {
      const roots: Roots_roots[] = (await callHasura(accessToken, query)).roots
      dispatch(fetchRootsSuccess(roots))
      dispatch(networkingSuccess())
    } catch (error: any) {
      dispatch(networkingFailure())
    }
  }
}

export function insertRootAppearanceAction(accessToken: string, rootAppearance: root_appearances_insert_input, conceptValue: string) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, insertRootAppearanceQuery(rootAppearance))
      const result: ConceptByValue = await callHasura(accessToken, fetchConceptQuery(conceptValue, CONFIG.CORE_CURRICULUM_ID))
      dispatch(setConcept(result.concepts[0]))
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function insertConceptsAction(accessToken: string, concepts: string[], newConcepts: string[], userId: string, curriculum_id: number) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      // insert new concepts
      await callHasura(accessToken, insertConceptsQuery(newConcepts.map((display_name) => ({ display_name, curriculum_id }))))
      // assign to user
      const user: User = await callHasura(accessToken, fetchUserQuery(userId))
      const conceptQueue = uniq(compact(concepts.concat(...(user.users_by_pk?.concepts_queue_denormalized || "")?.split(",")))).join(",")
      dispatch(updateAdminConceptsQueueAction(accessToken, userId, conceptQueue))
      dispatch(setNotificationAction(NotificationId.ConceptsCreated))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function deleteRootAppearanceAction(accessToken: string, rootId: number, conceptValue: string) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, deleteRootAppearanceQuery(rootId))
      const result: ConceptByValue = await callHasura(accessToken, fetchConceptQuery(conceptValue, CONFIG.CORE_CURRICULUM_ID))
      dispatch(setConcept(result.concepts[0]))
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}
