import first from "lodash/first"
import isInteger from "lodash/isInteger"
import sortBy from "lodash/sortBy"
import range from "lodash/range"
import mapValues from "lodash/mapValues"
import flatten from "lodash/flatten"
import moment from "moment"
import omit from "lodash/omit"
import uniqBy from "lodash/uniqBy"
import { createSlice } from "@reduxjs/toolkit"
import { loadStripe } from "@stripe/stripe-js"
import { navigate } from "gatsby"

import {
  LeaderboardGroup,
  LeaderboardTime,
  LeaderboardType,
  completedLevelQuery,
  createCheckoutSessionQuery,
  createCustomerPortalSessionQuery,
  dropStudentQuery,
  fetchUserQuery,
  fetchUsersStartingWithQuery,
  insertPedagogueEventQuery,
  insertUserSequenceQuery,
  leaderboardsAroundPositionQuery,
  leaderboardsAroundUserQuery,
  leaderboardsPositionsQuery,
  updateAdminConceptsQueueQuery,
  updateAdminPassagesQueueQuery,
  updatePasswordQuery,
  updateUserDisplayNameQuery,
  updateUserMetadataQuery,
  updateUserOtherQueuesQuery,
  updateUserRoleQuery,
  updateUserSettingsQuery,
  deleteStudentQuery,
  studentReportQuery,
  hasPremiumQuery,
  updateStarCountQuery,
  updateUserIsIndividualQuery,
  updateUserGroupIdQuery,
  updateUserCurriculumIdQuery,
  insertUserEventQuery,
  fetchUserEventsQuery,
  updateUserHasPremiumQuery,
  fetchRecentAndPremiumUsersQuery,
} from "../queries/user"

import AuthService from "../../services/auth"
import CONFIG from "../../config"
import callHasura from "../callHasura"
import { ADMIN_ARCHIVE_PASSAGE, ADMIN_REJECT_PASSAGE } from "../../lib/pedagogeEventTypeIds"
import { CreateCustomerPortalSession } from "../queries/types/CreateCustomerPortalSession"
import { LeaderboardsAroundPosition_leaderboards_around_position } from "../queries/types/LeaderboardsAroundPosition"
import { LeaderboardsAroundUser_leaderboards_around_user } from "../queries/types/LeaderboardsAroundUser"
import { LeaderboardsPositions, LeaderboardsPositions_positions } from "../queries/types/LeaderboardsPositions"
import { NotificationId, setNotificationAction } from "./notification"
import { Role } from "../../interfaces/role"
import { SequenceType } from "./sequence"
import { SolvedQuestion } from "../../components/question/lib/conceptProgress"
import { User_users_by_pk, User_users_by_pk_classrooms_students } from "../queries/types/User"
import { UsersStartingWith } from "../queries/types/UsersStartingWith"
import { defaultSetLoading, defaultNetworkingFailure, defaultNetworkingSuccess } from "./common"
import { downloadCsv, formatUsername, OLOG, parseRole, randomPassword, sleep } from "../../lib/helpers"
import { pedagogue_events_insert_input, user_events_insert_input, user_sequences_insert_input } from "../../../types/globalTypes"
import { updatePassageStatusQuery } from "../queries/passage"
import { StudentReport } from "../queries/types/StudentReport"
import { UserEvents, UserEvents_user_events } from "../queries/types/UserEvents"
import { RecentAndPremiumUsers, RecentAndPremiumUsers_users } from "../queries/types/RecentAndPremiumUsers"

export type User = User_users_by_pk_classrooms_students | User_users_by_pk

export interface SignUpParams {
  displayName: string
  email: string
  password: string
}

export type Leaderboard = LeaderboardsAroundUser_leaderboards_around_user | LeaderboardsAroundPosition_leaderboards_around_position

export interface UserState {
  accessToken?: string
  authorizationErrorMessage?: string
  classLeaderboard?: Leaderboard
  globalLeaderboard?: Leaderboard
  hasPremium: boolean
  isAdmin: boolean
  isQuerying: { [id: string]: boolean }
  isStudent: boolean
  isTeacher: boolean
  isPedagogue: boolean
  isTestingAsStudent: boolean
  leaderboardsPositions: LeaderboardsPositions_positions[]
  recentAndPremiumUsers: RecentAndPremiumUsers_users[]
  hasSchoolPremium: boolean
  userEvents?: UserEvents_user_events[]
  role?: Role
  signUpParams?: SignUpParams
  user?: User_users_by_pk
}

const initialState: UserState = {
  hasPremium: false,
  isAdmin: false,
  isQuerying: {},
  recentAndPremiumUsers: [],
  isStudent: false,
  hasSchoolPremium: false,
  isPedagogue: false,
  isTeacher: false,
  isTestingAsStudent: false,
  leaderboardsPositions: [],
}

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLoading: defaultSetLoading,
    networkingFailure: defaultNetworkingFailure,
    networkingSuccess: defaultNetworkingSuccess,

    notLoggedIn: () => {
      navigate("/")
    },

    setAccessToken: (state, { payload }) => {
      state.accessToken = payload
    },

    fetchUserSuccess: (state, { payload }: { payload: { user: User_users_by_pk; role: Role; hasPremium: boolean; hasSchoolPremium: boolean } }) => {
      const navigateHome = ["", "/", "/signup/student", "/signup/teacher"].includes(window.location.pathname.replace(/\/+$/, ""))

      if (navigateHome) {
        navigate("/home")
        window.scrollTo(0, 0)
      }

      state.user = payload.user
      state.hasPremium = payload.hasPremium
      state.hasSchoolPremium = payload.hasSchoolPremium

      if (!state.isTestingAsStudent) {
        state.role = payload.role
        const isAdmin = payload.role === Role.Admin
        state.isAdmin = isAdmin
        state.isPedagogue = payload.role === Role.Pedagogue
        state.isTeacher = payload.role === Role.Teacher
        state.isStudent = payload.role === Role.Student
      }
    },

    fetchUserFailure: (_) => {
      new AuthService().deleteAccessTokenCookie()
    },

    setSignUpParams: (state, { payload }: { payload: SignUpParams | undefined }) => {
      state.signUpParams = payload
    },

    unsetLeaderboards: (state) => {
      state.globalLeaderboard = undefined
      state.classLeaderboard = undefined
    },

    classLeaderboardAroundUserSuccess: (state, { payload }: { payload: Leaderboard }) => {
      state.classLeaderboard = payload
    },

    globalLeaderboardAroundUserSuccess: (state, { payload }: { payload: Leaderboard }) => {
      state.globalLeaderboard = payload
    },

    leaderboardsPositionsSuccess: (state, { payload }: { payload: LeaderboardsPositions_positions[] }) => {
      state.leaderboardsPositions = payload
    },

    fetchRecentAndPremiumUserSuccess: (state, { payload }: { payload: RecentAndPremiumUsers_users[] }) => {
      state.recentAndPremiumUsers = payload
    },

    fetchUserEventsSuccess: (state, { payload }: { payload: UserEvents_user_events[] }) => {
      state.userEvents = payload
    },

    setAuthorizationErrorMessage: (state, { payload }: { payload: string | undefined }) => {
      state.authorizationErrorMessage = payload
    },

    testAsStudent: (state, { payload }: { payload: boolean }) => {
      state.isTestingAsStudent = payload
      state.isStudent = payload
      state.isTeacher = !payload
      window.scrollTo(0, 0)
    },

    createCustomerPortalSuccess: (_, { payload }: { payload: string }) => {
      window.location.href = payload
    },
  },
})

export const {
  setLoading,
  networkingFailure,
  notLoggedIn,
  setAccessToken,
  fetchUserSuccess,
  networkingSuccess,
  createCustomerPortalSuccess,
  fetchUserFailure,
  setSignUpParams,
  fetchRecentAndPremiumUserSuccess,
  fetchUserEventsSuccess,
  globalLeaderboardAroundUserSuccess,
  setAuthorizationErrorMessage,
  classLeaderboardAroundUserSuccess,
  leaderboardsPositionsSuccess,
  unsetLeaderboards,
  testAsStudent,
} = userSlice.actions

export const userSelector = (state: any) => state.user

export default userSlice.reducer

export function fetchUsersStartingWithAction(displayName: string) {
  return async (dispatch: any) => {
    try {
      // TODO: - https://trello.com/c/zWEheC3N/206-last-names
      const split = displayName!.toLowerCase().split(" ")
      const startsWith = split.length > 1 ? `${split[0][0]}${split[1]}` : split[0]

      const result: UsersStartingWith = await callHasura("", fetchUsersStartingWithQuery(startsWith))

      const auth = new AuthService()

      const password = randomPassword()

      if (result.users.length) {
        const suffixes = result.users
          .map((s) => parseInt(formatUsername(s.email).toLowerCase().replace(startsWith, ""), 10))
          .filter(isInteger)
          .sort((a: number, b: number) => a - b)
        const suffix = suffixes.length ? range(1, 1000).find((n) => !suffixes.includes(n)) : 1
        dispatch(setSignUpParams({ displayName, password, email: auth.getEmailFor(`${startsWith}${suffix}`) }))
      } else {
        dispatch(setSignUpParams({ displayName, password, email: auth.getEmailFor(startsWith) }))
      }
    } catch (error) {
      OLOG(`ERROR ${error}`, true)
      dispatch(networkingFailure())
    }
  }
}

export function downloadStudentReportAction(accessToken: string, id: string) {
  return async (dispatch: any) => {
    const query = studentReportQuery(id)
    dispatch(setLoading(query.name))

    try {
      const result: StudentReport = await callHasura(accessToken, query)
      const user = result.users_by_pk

      if (user) {
        const filename = `wordcraft_words-${new Date().toLocaleDateString("en", { day: "numeric", month: "numeric", year: "2-digit" })}-${
          user.display_name
        }.csv`

        const headers = "Word,Date Learned,Stars,Accuracy"

        const rows = [headers].concat(
          ...sortBy(user.experience, "concept.display_name").map((e) =>
            flatten([
              e.concept?.display_name,
              moment(e.created_at).format("MMM D YYYY"),
              e.correct,
              `${Math.round((100 * e.correct) / e.seen)}%`,
            ]).join(",")
          )
        )

        downloadCsv(filename, rows)
      }

      dispatch(networkingSuccess(query.name))
    } catch (error: any) {
      OLOG(`ERROR ${error}`, true)
      dispatch(networkingFailure([query.name, error]))
    }
  }
}

export function updateUserHasPremiumAction(accessToken: string, id: string, has_premium: boolean) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, updateUserHasPremiumQuery(id, has_premium))
      dispatch(networkingSuccess())
    } catch (error) {
      OLOG(`ERROR ${error}`, true)
      dispatch(networkingFailure())
    }
  }
}

export function fetchUserAction(accessToken: string, id: string) {
  return async (dispatch: any) => {
    const query = fetchUserQuery(id)
    dispatch(setLoading(query.name))

    try {
      const { users_by_pk }: { users_by_pk: User_users_by_pk } = await callHasura(accessToken, query)

      if (users_by_pk) {
        const role = CONFIG.SHOULD_TEST_USER ? CONFIG.TEST_USER.role : parseRole(accessToken)
        const PREMIUM_PRODUCT_IDS = ["prod_MybdL3Kzd0kyQS", "prod_LVSXxEfploIFFS"]
        const isStudent = role === Role.Student
        const hasSchoolPremium = Boolean(users_by_pk.group?.has_premium)
        const hasPremium = isStudent
          ? Boolean((await callHasura(accessToken, hasPremiumQuery(users_by_pk.id), true))?.has_premium)
          : Boolean(
              PREMIUM_PRODUCT_IDS.includes(first(users_by_pk.related_accounts?.stripe_profile?.subscriptions?.data)?.plan?.product || "") ||
                hasSchoolPremium
            )

        if (!isStudent && hasPremium && !users_by_pk.has_premium) {
          dispatch(updateUserHasPremiumAction(accessToken, users_by_pk.id, hasPremium))
        }

        dispatch(fetchUserSuccess({ user: users_by_pk, role, hasPremium, hasSchoolPremium }))
      } else {
        dispatch(fetchUserFailure())
      }
      dispatch(networkingSuccess(query.name))
    } catch (error: any) {
      dispatch(fetchUserFailure())
      dispatch(networkingFailure([error, query.name]))
    }
  }
}

export function fetchUserEventsAction(accessToken: string, role: Role) {
  return async (dispatch: any) => {
    const query = fetchUserEventsQuery(role)
    dispatch(setLoading(query.name))

    try {
      const result: UserEvents = await callHasura(accessToken, query)
      dispatch(fetchUserEventsSuccess(result.user_events))
      dispatch(networkingSuccess(query.name))
    } catch (error: any) {
      dispatch(networkingFailure([error, query.name]))
    }
  }
}

export function setAuthorizationErrorMessageAction(message?: string) {
  return async (dispatch: any) => {
    dispatch(setAuthorizationErrorMessage(message))
  }
}

export function completedLevelAction(
  accessToken: string,
  user: User_users_by_pk,
  solvedQuestions: SolvedQuestion[],
  type: SequenceType,
  imageIds: number[],
  unlocked?: string
) {
  return async (dispatch: any) => {
    const questions = uniqBy(solvedQuestions, (s) => (s.passage_id ? `passage-${s.passage_id}` : `concept-${s.concept_id}`)).map((s) => {
      const isConceptQuestion = s.passage_id === null
      const attr = isConceptQuestion ? "concept_id" : "passage_id"
      if (!isConceptQuestion) s.concept_id = null
      s.discovered = !user?.experience.some((e) => e[attr] === s[attr])
      return s
    })

    OLOG(`saving ${questions.length} questions:\n${questions.map((s) => `\t${s.display_name}${s.discovered ? " (discovered)" : ""}`).join("\n")}`)

    const query = completedLevelQuery(
      user.id,
      solvedQuestions.map((q) => omit(q, "display_name")),
      type,
      imageIds,
      unlocked
    )

    dispatch(setLoading(query.name))

    try {
      await callHasura(accessToken, query, CONFIG.IS_DEVELOPMENT)
      dispatch(fetchUserAction(accessToken, user.id))
      dispatch(networkingSuccess(query.name))
    } catch (error: any) {
      OLOG(`ERROR ${error}`, true)
      dispatch(networkingFailure([query.name, error.message]))
    }
  }
}

export function updateUserDisplayNameAction(accessToken: string, id: string, display_name: string) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, updateUserDisplayNameQuery(id, display_name))
      dispatch(networkingSuccess())
    } catch (error) {
      OLOG(`ERROR ${error}`, true)
      dispatch(networkingFailure())
    }
  }
}

export function updateAdminConceptsQueueAction(accessToken: string, id: string, concepts_queue_denormalized: string) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, updateAdminConceptsQueueQuery(id, concepts_queue_denormalized))
      await dispatch(fetchUserAction(accessToken, id))
      dispatch(networkingSuccess())
    } catch (error) {
      OLOG(`ERROR ${error}`, true)
      dispatch(networkingFailure())
    }
  }
}

export function dropStudentAction(accessToken: string, id: string) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, dropStudentQuery(id))
      dispatch(networkingSuccess())
    } catch (error) {
      OLOG(`ERROR ${error}`, true)
      dispatch(networkingFailure())
    }
  }
}

export function updateStarCountAction(accessToken: string, id: string) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, updateStarCountQuery(id))
      dispatch(networkingSuccess())
    } catch (error) {
      OLOG(`ERROR ${error}`, true)
      dispatch(networkingFailure())
    }
  }
}

export function updateUserIsIndividualAction(accessToken: string, id: string, is_individual: boolean) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, updateUserIsIndividualQuery(id, is_individual))
      dispatch(networkingSuccess())
    } catch (error) {
      OLOG(`ERROR ${error}`, true)
      dispatch(networkingFailure())
    }
  }
}

export function updateUserGroupIdAction(accessToken: string, id: string, group_id: number) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, updateUserGroupIdQuery(id, group_id))
      dispatch(networkingSuccess())
    } catch (error) {
      OLOG(`ERROR ${error}`, true)
      dispatch(networkingFailure())
    }
  }
}

export function updateUserCurriculumIdAction(accessToken: string, id: string, curriculum_id: number) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, updateUserCurriculumIdQuery(id, curriculum_id))
      dispatch(networkingSuccess())
    } catch (error) {
      OLOG(`ERROR ${error}`, true)
      dispatch(networkingFailure())
    }
  }
}

export function deleteStudentAction(accessToken: string, id: string) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, deleteStudentQuery(id))
      dispatch(networkingSuccess())
    } catch (error) {
      OLOG(`ERROR ${error}`, true)
      dispatch(networkingFailure())
    }
  }
}

export function updatePasswordAction(accessToken: string, id: string, password: string) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, updatePasswordQuery(id, password))
      dispatch(setNotificationAction(NotificationId.PasswordReset))
      dispatch(networkingSuccess())
    } catch (error) {
      OLOG(`ERROR ${error}`, true)
      dispatch(setNotificationAction(NotificationId.SomethingBadHappened))
      dispatch(networkingFailure())
    }
  }
}

export function updateUserSettingsAction(
  accessToken: string,
  id: string,
  display_name: string,
  school: string,
  leaderboards_disabled: boolean,
  autoHintingDisabled: boolean,
  ai_report_section_enabled: boolean,
  sound_effects_enabled: boolean,
  pause_subscription_in_summer: boolean
) {
  return async (dispatch: any) => {
    const settings = {
      autoHintingDisabled,
    }
    const query = updateUserSettingsQuery(
      id,
      display_name,
      school,
      leaderboards_disabled,
      ai_report_section_enabled,
      sound_effects_enabled,
      pause_subscription_in_summer,
      settings
    )

    dispatch(setLoading(query.name))

    try {
      await callHasura(accessToken, query)
      dispatch(fetchUserAction(accessToken, id))
      dispatch(setNotificationAction(NotificationId.SettingsUpdated))
      dispatch(networkingSuccess(query.name))
    } catch (error: any) {
      OLOG(`ERROR ${error}`, true)
      dispatch(setNotificationAction(NotificationId.SomethingBadHappened))
      dispatch(networkingFailure([query.name, error]))
    }
  }
}

export function globalLeaderboardsAroundUserAction(
  accessToken: string,
  type: LeaderboardType,
  time: LeaderboardTime,
  id: string,
  position?: number,
  groupId?: number
) {
  return async (dispatch: any) => {
    const group = LeaderboardGroup.Global
    const query = position
      ? leaderboardsAroundPositionQuery(id, position, group, type, time, groupId)
      : leaderboardsAroundUserQuery(id, group, type, time, groupId)

    dispatch(setLoading(query.name))

    try {
      const result = await callHasura(accessToken, query, CONFIG.IS_DEVELOPMENT)
      dispatch(globalLeaderboardAroundUserSuccess(result.leaderboards_around_user || result.leaderboards_around_position))
      dispatch(networkingSuccess(query.name))
    } catch (error: any) {
      dispatch(networkingFailure([query.name, error.message]))
    }
  }
}

export function classLeaderboardsAroundUserAction(
  accessToken: string,
  type: LeaderboardType,
  time: LeaderboardTime,
  id: string,
  position?: number,
  groupId?: number
) {
  return async (dispatch: any) => {
    const group = LeaderboardGroup.Class
    const query = position
      ? leaderboardsAroundPositionQuery(id, position, group, type, time, groupId)
      : leaderboardsAroundUserQuery(id, group, type, time, groupId)

    dispatch(setLoading(query.name))

    try {
      const result = await callHasura(accessToken, query, CONFIG.IS_DEVELOPMENT)
      dispatch(classLeaderboardAroundUserSuccess(result.leaderboards_around_user || result.leaderboards_around_position))
      dispatch(networkingSuccess(query.name))
    } catch (error: any) {
      dispatch(networkingFailure([query.name, error.message]))
    }
  }
}

export function leaderboardsPositionsAction(accessToken: string, id: string) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    const query = leaderboardsPositionsQuery(id)

    try {
      const result: LeaderboardsPositions = await callHasura(accessToken, query, CONFIG.IS_DEVELOPMENT)
      dispatch(leaderboardsPositionsSuccess(result.positions))
    } catch (error) {
      OLOG(`ERROR ${error}`, true)
      dispatch(networkingFailure())
    }
  }
}

export function unsetLeaderboardsAction() {
  return async (dispatch: any) => {
    dispatch(unsetLeaderboards())
  }
}

export function insertUserSequenceAction(accessToken: string, object: user_sequences_insert_input) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, insertUserSequenceQuery(object))
      dispatch(networkingSuccess())
    } catch (error) {
      OLOG(`ERROR ${error}`, true)
      dispatch(networkingFailure())
    }
  }
}

export function insertUserEventAction(accessToken: string, object: user_events_insert_input) {
  return async (dispatch: any) => {
    if (CONFIG.IS_DEVELOPMENT) return

    dispatch(setLoading())

    try {
      await callHasura(accessToken, insertUserEventQuery(object))
      dispatch(networkingSuccess())
    } catch (error) {
      OLOG(`ERROR ${error}`, true)
      dispatch(networkingFailure())
    }
  }
}

export function updateAdminPassagesQueueAction(accessToken: string, id: string, passages_queue_denormalized: string) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, updateAdminPassagesQueueQuery(id, passages_queue_denormalized))
      dispatch(fetchUserAction(accessToken, id))
      dispatch(networkingSuccess())
    } catch (error) {
      OLOG(`ERROR ${error}`, true)
      dispatch(networkingFailure())
    }
  }
}

export function createCheckoutSessionAction(accessToken: string, id: string) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      const result = await callHasura(accessToken, createCheckoutSessionQuery(id), true)
      const stripe = await loadStripe(CONFIG.STRIPE_PUBLISHABLE_KEY!)
      if (stripe) stripe.redirectToCheckout({ sessionId: result.create_checkout_session })
      dispatch(networkingSuccess())
    } catch (error) {
      OLOG(`ERROR ${error}`, true)
      dispatch(networkingFailure())
    }
  }
}

export function createCustomerPortalSessionAction(accessToken: string, customerId: string) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      const result: CreateCustomerPortalSession = await callHasura(accessToken, createCustomerPortalSessionQuery(customerId), true)
      if (result.create_customer_portal_session) dispatch(createCustomerPortalSuccess(result.create_customer_portal_session))
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function setAccessTokenAction(accessToken: string) {
  return async (dispatch: any) => dispatch(setAccessToken(accessToken))
}

export function notLoggedInAction() {
  return async (dispatch: any) => dispatch(notLoggedIn())
}

export function unsetSignUpParamsAction() {
  return async (dispatch: any) => dispatch(setSignUpParams())
}

export function testAsStudentAction(on: boolean) {
  return async (dispatch: any) => dispatch(testAsStudent(on))
}

export function updateUserRoleAction(accessToken: string, id: string, role: string, needs_default_classroom = false) {
  return async (dispatch: any) => {
    const query = updateUserRoleQuery(id, role, needs_default_classroom)
    dispatch(setLoading(query.name))

    try {
      await callHasura(accessToken, query)
      new AuthService().logOut()
      await sleep(1)
      dispatch(networkingSuccess(query.name))
    } catch (error: any) {
      OLOG(`ERROR ${error}`, true)
      dispatch(networkingFailure([query.name, error]))
    }
  }
}

export function updateUserMetadataAction(
  accessToken: string,
  id: string,
  grades: string,
  subrole: string,
  school: string | null,
  source: string | null
) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, updateUserMetadataQuery(id, grades, subrole, school, source))
      await dispatch(fetchUserAction(accessToken, id))
      dispatch(networkingSuccess())
    } catch (error) {
      OLOG(`ERROR ${error}`, true)
      dispatch(networkingFailure())
    }
  }
}

export function insertPedagogueEventAction(accessToken: string, event: pedagogue_events_insert_input) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, insertPedagogueEventQuery(event))
      dispatch(networkingSuccess())
    } catch (error) {
      OLOG(`ERROR ${error}`, true)
      dispatch(networkingFailure())
    }
  }
}

export function reviewOutsourcerPassageAction(
  accessToken: string,
  user: User_users_by_pk,
  passage_id: number,
  outsourcerDisplayName: string,
  pedagogue_event_type_id: number
) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      const user_id = {
        monicahpan: "auth0|61ae778d6751ee006cbdaa28",
        otplunkett: "google-oauth2|109072470665309799621",
      }[outsourcerDisplayName]

      const event: pedagogue_events_insert_input = {
        passage_id,
        user_id,
        pedagogue_event_type_id,
      }

      await callHasura(accessToken, insertPedagogueEventQuery(event))

      const status = {
        [ADMIN_REJECT_PASSAGE]: "unlike",
        [ADMIN_ARCHIVE_PASSAGE]: "review",
      }[pedagogue_event_type_id]

      if (status) {
        await callHasura(accessToken, updatePassageStatusQuery(passage_id, status))
      }

      const otherQueues = mapValues(user.other_queues || {}, (ids) => ids.filter((id: any) => id !== passage_id))
      await callHasura(accessToken, updateUserOtherQueuesQuery(user.id, otherQueues))
      await dispatch(fetchUserAction(accessToken, user.id))
      dispatch(networkingSuccess())
    } catch (error) {
      OLOG(`ERROR ${error}`, true)
      dispatch(networkingFailure())
    }
  }
}

export function fetchRecentAndPremiumUsersAction(accessToken: string) {
  return async (dispatch: any) => {
    const query = fetchRecentAndPremiumUsersQuery()
    dispatch(setLoading(query.name))

    try {
      const result: RecentAndPremiumUsers = await callHasura(accessToken, query)
      dispatch(fetchRecentAndPremiumUserSuccess(result.users))
      dispatch(networkingSuccess(query.name))
    } catch (error: any) {
      dispatch(networkingFailure([query.name, error]))
    }
  }
}
